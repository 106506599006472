<template>
  <main>
    <Row :gutter="15">
      <Col :span="17">
        <ButtonGroup shape="circle">
          <Button icon="md-return-left" shape="circle" @click="goBack">返回</Button>
          <Button icon="md-refresh" @click="loadData">刷新</Button>
          <Button icon="md-add-circle" @click="create" v-if="Auth([ 'ExamQuestionBank', 'create' ])">添加</Button>
        
        </ButtonGroup>
      </Col>
     
      <Col :span="7">
        <searcher ref="searcher" :options="searches" @on-change="loadData"></searcher>
      </Col>
    </Row>
    <Divider/>
    <Tabs type="card" @on-click="changeType">
      <TabPane label="全部" name="0"></TabPane>
      <TabPane label="单选题" name="1"></TabPane>
      <TabPane label="多选题" name="2"></TabPane>
      <TabPane label="填空题" name="3"></TabPane>
      <TabPane label="判断题" name="4"></TabPane>
      <TabPane label="论述题" name="5"></TabPane>
      <TabPane label="阅读理解" name="6"></TabPane>
    </Tabs>
    <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

    <Divider/>
    <pagination ref="pagination" :total="total" @on-change="loadData"/>
  </main>
</template>

<script>
export default {
  data() {
    return {
      searches: {
        question_name: "题目",
        number:'题号'
      },
      question_type: 0,
      exam_id: this.$route.params.exam_id,
      question_subject_id: this.$route.params.question_subject_id,
      sort: "",
      total: 0,
      data: [],
      columns: [
        {
          title: "ID",
          key: "id",
          sortable: "custom",
          align: "center",
          minWidth: 80
        },
        {
          title: "题号",
          key: "number",
          sortable: "custom",
          align: "center",
          minWidth: 100
        },
        {
          title: "题目分类",
          sortable: "custom",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            return h("span", params.row.subject.category.name);
          }
        },
        {
          title: "科目",
          sortable: "custom",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            return h("span", params.row.subject.name);
          }
        },
        {
          title: "试卷",
          sortable: "custom",
          align: "center",
          minWidth: 240,
          render: (h, params) => {
            return h("span", params.row.exam.name);
          }
        },
        {
          title: "题目",
          key: "question_name",
          sortable: "custom",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            if (params.row.question_name_type) {
              return h("div", { class: "text-center" }, [
                h("img", {
                  attrs: {
                    src: params.row.question_name_url
                      ? params.row.question_name_url
                      : ""
                  },
                  class: "contain block-center mg-tb-xs"
                })
              ]);
            } else {
              return h("span", params.row.question_name.substr(0, 10));
            }
          }
        },
        {
          title: "题目类型",
          key: "question_type",
          sortable: "custom",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            switch (params.row.question_type) {
              case 1:
                return h("Tag", { props: { color: "orange" } }, "单选题");

              case 2:
                return h("Tag", { props: { color: "green" } }, "多选题");

              case 3:
                return h("Tag", { props: { color: "cyan" } }, "填空题");

              case 4:
                return h("Tag", { props: { color: "gold" } }, "判断题");

              case 5:
                return h("Tag", { props: { color: "blue" } }, "论述题");

              case 6:
                return h("Tag", { props: { color: "purple" } }, "阅读理解");

              default:
                return h("Tag", "-");
            }
          }
        },
        {
          title: "分数",
          key: "score",
          sortable: "custom",
          align: "center",
          minWidth: 100
        },
        {
          title: "选项A",
          key: "option1",
          sortable: "custom",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            if (params.row.option_type) {
              return h("div", { class: "text-center" }, [
                h("img", {
                  attrs: {
                    src: params.row.option1_url ? params.row.option1_url : ""
                  },
                  class: "contain block-center mg-tb-xs"
                })
              ]);
            } else {
              return h("span", params.row.option1.substr(0, 10));
            }
          }
        },
        {
          title: "选项B",
          key: "option2",
          sortable: "custom",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            if (params.row.option_type) {
              return h("div", { class: "text-center" }, [
                h("img", {
                  attrs: {
                    src: params.row.option2_url ? params.row.option2_url : ""
                  },
                  class: "contain block-center mg-tb-xs"
                })
              ]);
            } else {
              return h("span", params.row.option2.substr(0, 10));
            }
          }
        },
        {
          title: "选项C",
          key: "option3",
          sortable: "custom",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            if (params.row.option_type) {
              return h("div", { class: "text-center" }, [
                h("img", {
                  attrs: {
                    src: params.row.option3_url ? params.row.option3_url : ""
                  },
                  class: "contain block-center mg-tb-xs"
                })
              ]);
            } else {
              return h("span", params.row.option3.substr(0, 10));
            }
          }
        },
        {
          title: "选项D",
          key: "option4",
          sortable: "custom",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            if (params.row.option_type) {
              return h("div", { class: "text-center" }, [
                h("img", {
                  attrs: {
                    src: params.row.option4_url ? params.row.option4_url : ""
                  },
                  class: "contain block-center mg-tb-xs"
                })
              ]);
            } else {
              return h("span", params.row.option4.substr(0, 10));
            }
          }
        },
        {
          title: "正确答案",
          key: "answer",
          sortable: "custom",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            if (params.row.answer_type) {
              return h("div", { class: "text-center" }, [
                h("img", {
                  attrs: {
                    src: params.row.answer_url ? params.row.answer_url : ""
                  },
                  class: "contain block-center mg-tb-xs"
                })
              ]);
            } else {
              switch (params.row.question_type) {
                case 1:
                  return h("span", params.row.answer);

                case 2:
                  return h("span", params.row.answer);

                case 3:
                  return h("span", params.row.answer.substr(0, 10));

                case 4:
                  return h("span", params.row.answer ? "正确" : "错误");

                case 5:
                  return h("span", params.row.answer.substr(0, 10));

                default:
                  return h("Tag", "-");
              }
            }
          }
        },
        {
          title: "创建时间",
          minWidth: 180,
          key: "created_at",
          align: "center",
          sortable: "custom"
        },
        {
          title: "状态",
          minWidth: 90,
          key: "visible",
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h("Icon", {
              props: {
                type: params.row.visible
                  ? "ios-checkmark-circle"
                  : "ios-remove-circle"
              },
              class: params.row.visible ? "text-green" : "text-red"
            });
          }
        },
        {
          title: "操作",
          minWidth: 110,
          align: "center",
          render: (h, params) => {
            var html = [];

            if (this.Auth(["ExamQuestionBank", "update"])) {
              html.push(
                h("Button", {
                  props: {
                    type: "success",
                    size: "small",
                    icon: "md-create"
                  },
                  class: "mg-lr-xs",
                  on: {
                    click: () => {
                      this.update(params.row);
                    }
                  }
                })
              );
            }

            // if (this.Auth(["QuestionBank", "delete"])) {
            //   html.push(
            //     h("Button", {
            //       props: {
            //         type: "error",
            //         size: "small",
            //         icon: "md-trash"
            //       },
            //       class: "mg-lr-xs",
            //       on: {
            //         click: () => {
            //           this.remove(params.row);
            //         }
            //       }
            //     })
            //   );
            // }

            return h("div", html);
          }
        }
      ]
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let params = {
        exam_id:this.exam_id,
        question_type: this.question_type,
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        sort: this.sort,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit
      };

      this.$http.get("/examQuestionBank/list", { params: params }).then(res => {
        this.total = res.total;

        for (let i = 0; i < res.data.length; i++) {
          if (
            res.data[i].question_type == 0 ||
            res.data[i].question_type == 1
          ) {
            let oldAnswer = res.data[i].answer;
            let temp1 = oldAnswer.replace("0", "A");
            let temp2 = temp1.replace("1", "B");
            let temp3 = temp2.replace("2", "C");
            let temp4 = temp3.replace("3", "D");
            res.data[i].answer = temp4;
          }
        }
        this.data = res.data;
      });
    },
    changeSort(item) {
      this.sort = item.order == "normal" ? "" : item.key + "," + item.order;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    changeType(val) {
      this.question_type = val;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    create() {
      this.$router.push({name:'exam-question-bank-create',params:{exam_id:this.exam_id,question_subject_id:this.question_subject_id}});
    },
    update(item) {
      this.$router.push({name:'exam-question-bank-update',params:{id:item.id}});
    },

    remove(item) {
      this.$Modal.confirm({
        title: "确定要删除该题目吗？",
        content: item.name,
        onOk: () => {
          this.$http.post("/examQuestionBank/delete/id/" + item.id).then(res => {
            this.loadData();
          });
        }
      });
    },
    goBack(){
      this.$router.push("/exam");
    },
  }
};
</script>